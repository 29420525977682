import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Theme from '../components/layout/theme';
import { useForm, Controller } from "react-hook-form";
import api from '../services/api';
import { isAuthenticated, login, setCurrentUser } from '../services/auth';
import { Alert } from '@material-ui/lab';
import SendButton from "../components/form/send-button";
import { navigate } from 'gatsby';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.criaredavinci.com.br/">
        Criare Da Vinci
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LoginPage() {
  const classes = useStyles();

  const { control, handleSubmit } = useForm();
  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async data => {
    setLoginError(false);
    setLoading(true);

    try {
      const response = await api.post(`/auth/login`, data);
      login(response.data.access_token);
      
      const responseMe = await api.get('/me');
      setCurrentUser(responseMe.data);

      setLoading(false);
      navigate('/');
    } catch (error) {
      setLoginError(true);
      setLoading(false);
    }
  };

  if (isAuthenticated()) {
    navigate('/');
    return (<></>);
  }

  return (
    <Theme>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <img src={`/images/logo_criare_black.svg`} alt="Logo Criare" height={100} />
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)} method="post">
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField {...field} 
                  label="E-mail"
                  type="email"
                  autoComplete="email"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  autoFocus
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField {...field} 
                  label="Senha"
                  type="password"
                  autoComplete="current-password"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                />
              )}
            />

            {loginError && <Alert severity="error">Erro ao tentar autenticar. Verifique o usuário e senha informado.</Alert>}

            <SendButton
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              loading={loading}
            >
              Entrar
          </SendButton>
            <Grid container>
              <Grid item xs>
                <Link href="/recovery/request" variant="body2">
                  Esqueceu a senha?
              </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </Theme>
  );

}